@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

}

:root {
  --colorhover: rgb(255, 8, 8);
  --fondole: rgba(0, 157, 248, 0);
  --submenu-activo: 4px solid #2263b3;
  --border-menu-principal: 5px solid #0e9b49;
  --color-principal: #000;
  --color-iconos-header: #fff;
  --color-texto-barra-lateral: #000;
  --fuente-actual: "Poppins";
  --fondo-blanco-menu-lateral: 1
}

.ant-layout-header{
  background-color: #e7e7e7;
}

.ant-layout-sider-zero-width-trigger{
  top: 12px !important;
  background-color: #fff !important;
  color: #000 !important;
  right: -40px !important;
  border-radius: 4px !important;
}

.ant-layout-sider-trigger{
  background-color: #e7e7e7;
}

.ant-pagination-item-active {
  border-color: var(--color-principal) !important;
  color: var(--color-principal) !important;
}

.ant-spin-dot-item{
  background-color: #000 !important;
}

.ant-input {
  box-shadow: none !important;
}

.hoverOpacity:hover{
  /* resltar */
  opacity: 0.8;
  
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #eff2f5 !important;
}

img {
  backface-visibility: hidden;
}

.item-lista-widgets {
  padding: 10px;
  background: #f3f3f3;
  border-radius: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.widget {
  justify-content: center;
}

.widgetShadow {
  box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 8px 59px 4px rgba(0, 0, 0, 0.13);

}

.textoFondoOscuro {
  color: var(--color-iconos-header);
}

.ant-pagination-item-active a {
  color: var(--color-principal) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--color-principal) !important;
  box-shadow: none !important
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--color-principal) !important;
}

.ant-form-item-label{
  text-align: left !important;
}

body {
  font-family: var(--fuente-actual) !important;
  overflow-x: hidden
}

.ant-menu-item-active {
  color: var(--color-principal) !important
}



.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #fff !important;
  color: #000 !important
}

.ant-menu-submenu-horizontal :hover {
  color: var(--colorhover) !important;
}

.menuHeader {
  color: var(--color-iconos-header) !important;
  
}

.menuHeader::after{
  border-bottom: none !important;
}


.ant-menu-inline .ant-menu-item::after {
  border-right: var(--border-menu-principal) !important
}

.ant-menu-submenu-active {
  color: var(--colorhover) !important
}

.menuIzquierda span {
  color: var(--color-texto-barra-lateral) !important
}

.menuIzquierdaPadre .ant-menu-sub.ant-menu-inline{
  background-color: var(--fondole) !important;
}

.menuIzquierdaPadre span {
  color: var(--color-texto-barra-lateral) !important
}

.menuIzquierdaPadre i {
  color: var(--color-texto-barra-lateral) !important
}

.menuIzquierda img {
  filter: invert(var(--fondo-blanco-menu-lateral))
}



.ant-menu-item-selected {
  color: var(--colorhover) !important;
  background-color: var(--fondole) !important;
}

.menuHeader {
  color: #fff
}

.ant-menu-horizontal {
  display: flex;
  justify-content: flex-end;
}

.ant-menu-horizontal .ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
}

.linea {
  stroke: rgb(0, 0, 0);
  stroke-width: 2
}

.inputAcciones {
  margin-top: 10px;

}

.caja {
  max-width: 100%;
  margin-top: 10px;
  padding: 0px 25px 25px 25px;
  border: 1px solid rgb(219, 223, 234);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin-bottom: 30px;
}

.hoverCaja {
  max-width: 100%;
  margin-top: 10px;
  padding: 0px 25px 25px 25px;
  border: 1px solid rgb(219, 223, 234);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin-bottom: 30px;
}

.hoverCaja:hover{
  border: 1px solid rgb(153, 214, 147) !important
}

.hoverCaja .titular {
  margin: auto;
  margin-top: -15px;
  margin-left: 0;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  font-weight: 800;
  font-size: 23px;
  width: auto;
  outline: none;
}

.hoverCaja .subtitular {
  margin: auto;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border: none;
  color: rgb(97, 97, 97);
}

.caja .subtitular {
  margin: auto;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border: none;
  color: rgb(97, 97, 97);
}


.caja .titular {
  margin: auto;
  margin-top: -15px;
  margin-left: 0;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  font-weight: 800;
  font-size: 23px;
  width: auto;
  outline: none;
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.tagasatributo {
  display: flex;
  flex-direction: column-reverse;
}

select .Mui-focused {
  background-color: #000 !important;
}

.contendor {
  background-color: #000;
}

.ContenedorTagsAtributos {
  background: #e8ffeb;
  padding: 5px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputTagsAtributo {
  width: 90% !important;
  background: #deefe0;
  border: none;
  padding: 5px;
  outline: none;
}

.MuiInput-underline:before {
  content: "" !important;
  border-bottom: 0px !important;
}



.modalInput {

  background: #f3f3f3;
  padding: 10px;
  width: 320px;
  min-height: 50px;
  border-radius: 5px;
  position: absolute;
  margin-top: 5px;

}

.que {
  background-color: #57b9d0 !important;
  color: #fff;
}

.botonAccion {
  position: absolute !important;
  top: 43px !important;
  color: #52c57b !important;
}

.ant-notification-topLeft{
  overflow: auto;
  max-height: 100vh;
  margin: 10px;
  width: 420px;
  top: 5px;
  margin-left: 0px;
  justify-content: center;
  display: flex;
}

.banderaNegra{
  background-color: rgb(202, 202, 202);
  color: #000;
}

.banderaAmarilla{
  background-color: rgb(255, 255, 202);
  color: #000;
}

.banderaVerde{
  background-color: rgb(232, 255, 198);
  color: #000;
}

.banderaRoja{
  background-color: #ffe5e5;
  color: #000;
}

.banderaAzul{
  background-color: #cbf4ff;
  color: #000;
}


.and {
  background: #17986e !important;
  color: #fff;
}

.inputbotonguardar {
  position: absolute !important;
  top: -18px;
  right: 15px;
  background: #fff !important;
  color: #288a42 !important;
}

.react-tags {
  margin-top: 10px;
  position: relative;
  padding: 16px 0 10px 6px;
  border-bottom: 1px solid #D1D1D1;
  border-radius: 1px;
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.atributoNumero {
  color: green;
  background: none !important;
  border: none;
}

h2 {
  margin: 0
}


.tagboton {
  background: #f1f1f1;
  border: none;
  padding: 8px;
  font-weight: 300;
  font-size: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}



.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
  outline: none;
}

.react-tags__search {
  display: inline-block;
  flex: auto;
  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-wrapper{
  height: 100%;
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  height: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  min-width: 100%;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.shadow {
  box-shadow: 2px 2px 74px -5px rgba(85, 85, 85, 0.29);
  -webkit-box-shadow: 2px 2px 74px -5px rgba(85, 85, 85, 0.29);
  -moz-box-shadow: 2px 2px 74px -5px rgba(85, 85, 85, 0.29);
}

.tagsY{
  background: rgb(78 218 81);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
}

.tagsValorText{
  font-weight: bold;
}

.tagsDefault{
  background: rgb(239 242 245)
}

.tagsSinCondicionales{
  background: rgb(255 235 235)
}

.tagsAccionDefault{
  background: rgb(239 245 239)
}

.posicionEtapa{
  background-color: #ececec;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  color: rgb(46, 46, 46);
  cursor: pointer;
  font-size: 15px;
  
}

.iconoExpandirConsultas {
  background: #fff;
  margin-right: 15px;
  margin-left: 4px;
  position: absolute;
  font-size: 19px;
  left: 7px;
  top: -10px;
  cursor: pointer;
  color: #969696;
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.inputcondicional {

  width: 100%;
  height: 50px;
  border: 1px solid #d6d6d6;
  outline: none;
  font-size: 20px;
  padding: 10px;
  color: #000;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

}

.seleccionar {
  background: rgb(232 245 255 / 42%);
  border-radius: 4px;
  position: relative;
  margin: 0px;
  outline: none !important;
  padding: 20px;
  font-size: 16px;
  border: 0px
}

.Mui-focusVisible {
  border: 1px solid #000 !important;
  outline: none !important;

}

.Mui-focused {
  outline: tan !important;
  color: rgba(0, 0, 0, 0);
}

.elcontainer {
  padding: 10px
}

.m-2 {
  margin: 2px
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.mt-5 {
  margin-top: 3em;
}

.mb-1 {
  margin-bottom: 1em;
}

.flex-row {
  flex-direction: row;
}

.w-200 {
  width: 200em;
}

.border-2 {
  border: 0.5em solid rgb(221, 221, 221);
  width: 5em;
  height: 5em;
  border-radius: 50%;
  margin-right: 1em;
  cursor: pointer
}

.border-opacity-10 {}

.botonesgu {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.formularioBase {
  display: flex;
  flex-direction: column;
}

.formularioBase label {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}



.formularioBase input {
  min-height: 40px;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.formularioBase select {
  min-height: 40px;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
}

.formularioBase input[type="file"] {
  border: none
}

.tituloflujo {
  position: absolute;
  top: -14px;
  background: #fff;
  padding: 5px;
  font-weight: 200;
  font-size: 15px;
}

.submenu {
  margin: 0px;
  font-size: 0.8125rem;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  align-content: flex-start;
  align-items: flex-start;
}

table {
  /* border: 1px solid #dbdfea; */
  padding: 5px;
  border-collapse: collapse;
  min-width: 100%;
}

.rst__moveHandle,
.rst__loadingHandle {
  border: none !important;
  -webkit-box-shadow: 0px !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.rst__rowContents {
  -webkit-box-shadow: 0px !important;
  box-shadow: 0px 0px 0px 0px !important;
}


td {
  border-bottom: 1px solid #dbdfea
}

tr {
  background-color: #fff;
}

thead th {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8094ae;
  font-size: .9em;
  border-bottom: 1px solid #dbdfea;
  font-size: 13px;
  color: #8094ae;
}

td,
th {
  border-bottom: 1px solid #dbdfea;
  padding: 15px;
  vertical-align: middle;
  text-align: left;
}

.submenu li {
  margin-left: 10px;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
}

.ant-input {
  background: #f7f7f7 !important;
  border: 1px solid #ececec !important;
  min-height: 40px !important;
}

.inputs input,
textarea {
  color: rgb(54, 74, 99) !important;
  font-weight: 300 !important;
}

.submenu .activo {
  border-bottom: var(--submenu-activo)
}

.submenu div:hover {
  border-bottom: var(--submenu-activo)
}


.submenu div {
  font-size: 0.875rem;
  text-decoration: none;
  padding: 1rem 0;
  font-weight: 700;
  color: #526484;
  position: relative;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
}

.botonFlotante {
  position: absolute;
  z-index: 1000000;
  right: 10px;
  width: 40px;
  height: 40px;
  top: 10px;
  border-radius: 50%;
  border: 1px solid #f6f7f8;
  background: #d7f2d5;
  font-size: 32px;
  cursor: pointer;
}

.botonFlotante:focus {
  outline: 0
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

li {
  margin-top: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.inputBlanco{
  background-color: #fff !important;
}